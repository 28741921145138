import { atom } from 'recoil';
import { EmployeeProps, OrgProps } from '../../../pages/common/type/types';
import { localStorageEffect } from '../effect/Storage';
import { StoargeEnum } from '../effect/constant/StorageEnum';

/**
 * 조직정보 atom
 */
const orgsAtom = atom<OrgProps[]>({
  key: 'orgs',
  default: [],
  effects: [localStorageEffect(StoargeEnum.ORGS)],
});

/**
 * 사원정보 atom
 */
const employeesAtom = atom<EmployeeProps[]>({
  key: 'employees',
  default: [],
  effects: [localStorageEffect(StoargeEnum.EMPLOYEES)],
});
/**
 * 평가계획별 사원정보 atom
 */
const plansEmployeesAtom = atom<any[]>({
  key: 'plansEmployees',
  default: [],
  effects: [localStorageEffect(StoargeEnum.PLAN_EMPLOYEES)],
});
/**
 * 프로필사진 atom
 */
const profileImagesAtom = atom<any>({
  key: 'profileImages',
  default: {},
});
export { employeesAtom, orgsAtom, plansEmployeesAtom, profileImagesAtom };
